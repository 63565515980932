//LPのheader検索バー
$(function() {
  $(window).on('load scroll', function() {
    if ($(window).scrollTop() > 600) {
      $(".js-search").show();
    } else {
      $(".js-search").hide();
    }
  });
});

// SPメニュー
$(function() {
  $(".p-header__icon").on("click", function() {
    $(".p-header__tool").toggleClass("-active");
    $(".p-header__icon").toggleClass("-active");

    $(".p-header__overlay").fadeToggle();
  });

  $(".p-header__toolTrigger").on("click", function() {
    $(".p-header__tool").addClass("-active");
    $(".p-header__overlay").fadeIn();
  });
  $(".p-header__overlay").on("click", function() {
    $(".p-header__tool").removeClass("-active");
    $(".p-header__icon").removeClass("-active");

    $(".p-header__overlay").fadeOut();
  });
});

// flash
$(function() {
  setTimeout(function() {
    $('.c-flash').fadeOut();
  }, 3000);
});

// マイページasideのactive切り替え
$(function() {
  if ($('aside.l-aside').length) {
    let $page = $('.l-main').attr('id');
    $('aside.l-aside .' + $page).addClass('-active');
  }
});
$(function() {
  //料金タブactive切り替え
  $(".p-section__functionTabItem").on("click", function() {
    $(".-active").removeClass("-active");
    $(this).addClass("-active");
    $(".-show").removeClass("-show");
    const index = $(this).index();
    $(".p-section__functionPrice.-pc")
      .eq(index)
      .addClass("-show");
    $(".p-section__functionPrice.-sp")
      .eq(index)
      .addClass("-show");
  });
});

// heder検索
$(function() {
  $('.p-suggest__search').submit(function() {
    $(".overlay").css('display', 'block');
    $(".overlay .p-loading").css('display', 'block');
  });
});

// プラン変更 プラン請求額表示
$(function() {
  if ($('.toggle_plan_details').length) {
    $('input:radio').change(function() {
      $('.show-after-click').css('display', 'block');
    });
  }
});

// お客様の声カルーセル
$(function() {
  $('.p-section__reviewBlock').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: true,
    arrows: false,
    responsive: [{
      breakpoint: 1024,
      settings: {
        arrows: false,
        slidesToShow: 1
      }
    }]
  });
});

// 事例カルーセル
$(function() {
  $('.p-section__caseBlock').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: true,
    arrows: false,
    responsive: [{
      breakpoint: 1024,
      settings: {
        arrows: false,
        slidesToShow: 1
      }
    }]
  });
});

// モーダルとじる
$(function() {
  $(".p-popup__close").on("click", function() {
    $('.p-popup').fadeOut();
  });
});